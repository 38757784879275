<template>
  <section class="a404page">
    <secondary-nav></secondary-nav>

    <div class="su-body">
      <div class="su-section-seven container su-section-contact">
        <div class="row">
          <div class="col-1 void-column"></div>

          <div class="col-12 col-md-11">
            <div class="row">
              <div class="col-12">
                <h1 class="d-flex align-items-center flex-row">
                  Other positions
                  <b-spinner v-if="loadingFormRequest" type="grow" label="Loading" class="ml-3">
                  </b-spinner>
                </h1>
              </div>
            </div>
            <form class="row" @submit.prevent="submitEntry">
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="name">Complete name*</label>
                  <input
                    id="name"
                    name="name"
                    placeholder="Enter name here..."
                    type="text"
                    class="form-control"
                    v-model="form.name"
                    :disabled="loadingFormRequest"
                    :class="{ 'su-error-field': msg.name.length }"
                    @change="msg.name = ''"
                  />
                  <span class="su-error-field-text" v-if="msg.name.length">{{ msg.name }}</span>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="email">Email*</label>
                  <input
                    id="email"
                    name="email"
                    placeholder="Enter email here"
                    type="text"
                    class="form-control"
                    v-model="form.email"
                    :disabled="loadingFormRequest"
                    :class="{ 'su-error-field': msg.email.length }"
                    @change="
                      getImages;
                      msg.email = '';
                    "
                  />
                  <span class="su-error-field-text" v-if="msg.email.length">{{ msg.email }}</span>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group su-file">
                  <p for="email">Curriculum Vitae*</p>

                  <label for="file">{{
                    form.fileName != '' ? form.fileName : 'Select file...'
                  }}</label>

                  <input
                    id="file"
                    name="file"
                    placeholder="CV"
                    type="file"
                    class="form-control"
                    ref="file"
                    :disabled="loadingFormRequest"
                    :class="{ 'su-error-field': msg.cv.length }"
                    @change="
                      getFileName();
                      msg.cv = '';
                    "
                    accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                  <span class="su-error-field-text" v-if="msg.cv.length">{{ msg.cv }}</span>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group su-file">
                  <p for="email">Images to attach*</p>

                  <label for="images">{{
                    images.length > 0 ? images.length + ' image/es selected.' : 'Select images...'
                  }}</label>

                  <input
                    id="images"
                    name="images"
                    placeholder="Images"
                    type="file"
                    class="form-control"
                    ref="images"
                    :disabled="loadingFormRequest"
                    :class="{ 'su-error-field': msg.images.length }"
                    @change="
                      getImages();
                      msg.images = '';
                    "
                    accept=".jpg, .png, .jpeg"
                    multiple
                  />
                  <span class="su-error-field-text" v-if="msg.images.length">{{ msg.images }}</span>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="email">Send us your showreel*</label>
                  <input
                    id="url"
                    name="url"
                    placeholder="Enter your showreel link here"
                    type="text"
                    class="form-control"
                    v-model="form.url"
                    :disabled="loadingFormRequest"
                    :class="{ 'su-error-field': msg.url.length }"
                    @change="
                      getImages;
                      msg.url = '';
                    "
                  />
                  <span class="su-error-field-text" v-if="msg.url.length">{{ msg.url }}</span>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <label for="submit">Submit request</label>

                <div class="form-group">
                  <button
                    name="submit"
                    type="submit"
                    class="su-button"
                    :disabled="loadingFormRequest"
                  >
                    Send request now
                  </button>
                </div>
              </div>

              <div class="col-md-6 col-12 d-flex justify-content-center flex-column">
                <div class="form-group">
                  <div class="custom-control custom-checkbox custom-control-inline">
                    <input
                      name="terms"
                      id="terms"
                      type="checkbox"
                      class="custom-control-input"
                      value="terms"
                      v-model="form.privacy"
                      :disabled="loadingFormRequest"
                      :class="{ 'su-error-field': msg.privacy.length }"
                      @change="msg.privacy = ''"
                    />
                    <label for="terms" class="custom-control-label">
                      Agree to the <a href="/privacy-policy">Privacy Policy</a>*
                    </label>
                  </div>
                  <div>
                    <span class="su-error-field-text" v-if="msg.privacy.length">{{
                      msg.privacy
                    }}</span>
                  </div>
                  <div class="required_fields_label">
                    <label>*Required fields</label>
                  </div>
                </div>
              </div>

              <label
                for="imagesPreview"
                class="col-12 image-preview-component"
                :class="{ showImagePreview: images.length > 0 }"
              >
                Attachments
              </label>

              <div
                id="imagesPreview"
                class="image-preview-row image-preview-component"
                :class="{ showImagePreview: images.length > 0 }"
              >
                <div class="col-1 void-column"></div>

                <div class="col-md-10 col-12 row justify-content-center">
                  <div
                    class="col-md-4 col-12 image-preview-container"
                    v-for="(url, index) in urls"
                    :key="index"
                  >
                    <button
                      @click="deleteImage(index)"
                      class="delete-image-preview"
                      :disabled="loadingFormRequest"
                      type="button"
                    >
                      Delete
                    </button>
                    <img class="image-preview" :src="url" />
                  </div>
                </div>

                <div class="col-1 void-column"></div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <footer-row></footer-row>

      <alert-row ref="alertMsg"></alert-row>
    </div>
  </section>
</template>

<script lang="js">
import Vue from 'vue';
import SecondaryNavBar from "@/components/secondary-nav";
import Footer from '@/components/footer.vue'
import Alert from "@/components/alert";
import Loading from '@/components/loading'
import axios from "axios";

export default Vue.extend({
  name: 'contact-other',
  metaInfo: {
    title: 'Contact - Other positions',
  },
  components: {
    'secondary-nav' : SecondaryNavBar,
    'footer-row' : Footer,
    'alert-row' : Alert,
  },
  data(){
    return{
      form:{
        name: '',
        email: '',
        privacy: '',
        page: 'Other positions',
        fileName: '',
        url: ''
      },
      msg:{
        name: '',
        email: '',
        privacy: '',
        message: '',
        cv: '',
        images: '',
        url: ''
      },
      responseData: "",
      loadingFormRequest: false,
      images:[],
      urls:null
    }
  },
  methods: {
    getFileName(){
      if(this.$refs.file.files.length > 0)
        this.$data.form.fileName=this.$refs.file.files[0].name;
      else
        this.$data.form.fileName="";
    },
    getImages(){
      if(this.$refs.images.files.length > 3) {
        this.$refs.alertMsg.showAlert("At most three images are allowed.");
        return;
      }
      if(this.$refs.images.files.length > 0) {
        this.$data.images = Array.from(this.$refs.images.files);
        this.$data.urls = [];

        this.$data.images.forEach((image, index) => {
          this.$data.urls.push(URL.createObjectURL(image));
        });
      }
      else {
        this.$data.images = [];
        this.$data.urls = null;
      }
    },
    deleteImage(index){
      this.$data.images.splice(index, 1);
      this.$data.urls = [];
      this.$data.images.forEach((image) => {
        this.$data.urls.push(URL.createObjectURL(image));
      });
    },
    validateEmail(value){
      return /^[^@]+@\w+(\.\w+)+\w$/.test(value);
    },
    validateName(value){
      return /^[A-Za-z ]+$/.test(value);
    },
    toFormData: function (obj, formData) {
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    submitEntry: function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      var self = this;
      var localError = false;
      this.$data.loadingFormRequest = true;

      let formData = new FormData();
      this.toFormData(this.$data.form, formData);

      if(this.$data.images.length > 0){
        formData.append('imagesNumber', this.$data.images.length );
        this.toFormData(this.$data.images, formData);
      }
      else{
        this.$data.msg.images = "Images are required. At most three images are allowed.";
        this.$data.loadingFormRequest = false;
      }

      if(this.$refs.file.files.length > 0) {
        formData.append('attachment', this.$refs.file.files[0]);
      }
      else {
        this.$data.msg.cv = "A CV is Required.";
        localError = true;
      }

      if(!this.$data.form.name.length){
        this.$data.msg.name = "Name field can not be void.";
        localError = true;
      }

      else if(!this.validateName(this.$data.form.name)){
        this.$data.msg.name = "Name field not valid. Only letters are admitted.";
        localError = true;
      }

      if(!this.$data.form.email.length){
        this.$data.msg.email = "Email field can not be void.";
        localError = true;
      }
      else if(!this.validateEmail(this.$data.form.email)){
        this.$data.msg.email = "Email format non valid.";
        localError = true;
      }

      if(!this.$data.form.url.length){
        this.$data.msg.url = "URL can not be void.";
        localError = true;
      }

      if(!this.$data.form.privacy){
        this.$data.msg.privacy = "You must accept the privacy policy.";
        localError = true;
      }

      if(localError){
        this.$data.loadingFormRequest = false;
        return;
      }

      this.$refs.alertMsg.showAlert("Sending message. Please wait, it could take a while based on your connection.");


      axios.post('/php/showup_form.php', formData)
        .then(response => {
          var infoResponse = response.data;
          this.$refs.alertMsg.showAlert(infoResponse.message);

          if(infoResponse.status) {
            this.$data.form.email = "";
            this.$data.form.name = "";
            this.$data.form.privacy = false;
          }

          this.$data.loadingFormRequest = false;

        })
        .catch(error => {
          this.$refs.alertMsg.showAlert(error);
          this.$data.loadingFormRequest = false;

        });
    }
  }
});
</script>
