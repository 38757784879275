<template>
  <section class="a404page">
    <secondary-nav></secondary-nav>

    <div class="su-body">
      <div class="su-section-seven container su-section-contact">
        <div class="row">
          <div class="col-1 void-column"></div>
          <div class="col-12 col-md-11">
            <div class="row expetation-title">
              <div class="col-12">
                <p>Become our</p>
                <h1 class="d-flex align-items-center flex-row">
                  Technician
                  <b-spinner
                    v-if="loadingFormRequest"
                    type="grow"
                    label="Loading"
                    class="ml-3"
                  ></b-spinner>
                </h1>
                <p>
                  We are currently looking for talented and experienced Light Technician and Sound
                  Technician to join our team at Showup Productions. Please read the skills required
                  before applying.
                </p>
              </div>
            </div>

            <div class="row expetation-body">
              <div class="col-12">
                <b-card no-body>
                  <b-tabs pills card vertical>
                    <b-tab title="LIGHT TECHNICIAN" active>
                      <b-card-text>
                        <div class="row">
                          <div class="col-12 flex-row justify-content-between">
                            <ul>
                              <div class="row">
                                <li class="col-md-6 col-12">
                                  Demonstrated programming proficiency on the GrandMA2 and 3 series
                                  of lighting consoles.
                                </li>
                                <li class="col-md-6 col-12">
                                  Intermediate level of lighting design skills to accommodate
                                  special events in the venue.
                                </li>
                              </div>
                              <div class="row">
                                <li class="col-md-6 col-12">
                                  Ability to learn DisguiseArena Resolume, Pandora’s Box, Catalyst,
                                  Arkaos, Media server to a level sufficient for troubleshooting,
                                  basic content loading, and proper output routing.
                                </li>
                                <li class="col-md-6 col-12">
                                  Regularly inspect all LED Video surfaces, replacing panels as
                                  required.
                                </li>
                              </div>
                              <div class="row">
                                <li class="col-md-6 col-12">
                                  Regularly safety inspect and maintain lighting gear in the venue.
                                </li>
                                <li class="col-md-6 col-12">
                                  Effectively troubleshoot and report on all lighting or video
                                  issues.
                                </li>
                              </div>
                              <div class="row">
                                <li class="col-md-6 col-12">
                                  Preset, program (as applicable), and run all events taking place
                                  in assigned venue.
                                </li>
                                <li class="col-md-6 col-12">
                                  Availability to embark for 6-8 months contract.
                                </li>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>

                    <b-tab title="SOUND TECHNICIAN">
                      <b-card-text>
                        <div class="row">
                          <div class="col-12 flex-column justify-content-between">
                            <ul>
                              <div class="row">
                                <li class="col-md-6 col-12">
                                  Demonstrated programming proficiency on the QLab platform and
                                  Logic DAW.
                                </li>
                                <li class="col-md-6 col-12">
                                  Preset, program (as applicable), and run all events taking place
                                  in assigned venue.
                                </li>
                              </div>
                              <div class="row">
                                <li class="col-md-6 col-12">
                                  Demonstrated knowledge of the DANTE protocol.
                                </li>
                                <li class="col-md-6 col-12">
                                  Demonstrated knowledge of YAMAHA digital mixers (QL5, CL5, M7CL,
                                  LS9).
                                </li>
                              </div>
                              <div class="row">
                                <li class="col-md-6 col-12">
                                  Knowledge of the MIDI protocol and consequent synchronization
                                  programming.
                                </li>
                                <li class="col-md-6 col-12">
                                  Availability to embark for 6-8 months contract.
                                </li>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>

                    <b-tab title="VIDEO TECHNICIAN">
                      <b-card-text>
                        <div class="row">
                          <div class="col-12 flex-column justify-content-between">
                            <ul>
                              <div class="row">
                                <div class="col-12">
                                  <p>
                                    We are looking for male and female video technician who is
                                    familiar with the GrandMA 2/3 console, Media Server (Disguise
                                    D3, Pandora Box, ArKaos Media Master), Resolume, QLab, and has
                                    knowledge of video and broadcast mixer. Please note: candidates
                                    should also be open to international travel, including
                                    assignments aboard cruise ships.
                                  </p>
                                </div>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </div>
            </div>

            <form class="row su-form" @submit.prevent="submitEntry">
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="name">Complete name*</label>
                  <input
                    id="name"
                    name="name"
                    placeholder="Enter name here..."
                    type="text"
                    class="form-control"
                    v-model="form.name"
                    :disabled="loadingFormRequest"
                    :class="{ 'su-error-field': msg.name.length }"
                    @change="msg.name = ''"
                  />
                  <span class="su-error-field-text" v-if="msg.name.length">{{ msg.name }}</span>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="email">Email*</label>
                  <input
                    id="email"
                    name="email"
                    placeholder="Enter email here"
                    type="text"
                    class="form-control"
                    v-model="form.email"
                    :disabled="loadingFormRequest"
                    :class="{ 'su-error-field': msg.email.length }"
                    @change="msg.email = ''"
                  />
                  <span class="su-error-field-text" v-if="msg.email.length">{{ msg.email }}</span>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group su-file">
                  <p for="file">Curriculum Vitae*</p>
                  <label for="file">{{
                    form.fileName != '' ? form.fileName : 'Select file...'
                  }}</label>

                  <input
                    id="file"
                    name="file"
                    placeholder="CV"
                    type="file"
                    class="form-control"
                    ref="file"
                    :disabled="loadingFormRequest"
                    :class="{ 'su-error-field': msg.cv.length }"
                    @change="
                      getFileName();
                      msg.cv = '';
                    "
                    accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                  <span class="su-error-field-text" v-if="msg.cv.length">{{ msg.cv }}</span>

                  <small
                    style="
                      color: rgba(255, 255, 255, 0.5);
                      font-size: 0.85rem;
                      margin-top: 5px;
                      display: block;
                    "
                  >
                    Maximum file size: 256MB
                  </small>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <label for="submit">Submit request</label>
                <div class="form-group">
                  <button
                    name="submit"
                    type="submit"
                    class="su-button"
                    :disabled="loadingFormRequest"
                  >
                    Send request now
                  </button>
                </div>
              </div>

              <div class="col-md-6 col-12 d-flex justify-content-center flex-column">
                <div class="form-group">
                  <div
                    class="custom-control custom-checkbox custom-control-inline d-flex flex-column"
                  >
                    <div class="mb-3">
                      <input
                        name="terms"
                        id="terms"
                        type="checkbox"
                        class="custom-control-input"
                        value="terms"
                        v-model="form.privacy"
                        :disabled="loadingFormRequest"
                        :class="{ 'su-error-field': msg.privacy.length }"
                        @change="msg.privacy = ''"
                      />
                      <label for="terms" class="custom-control-label">
                        Agree to the <a href="/privacy-policy">Privacy Policy</a>*
                      </label>
                      <span class="su-error-field-text" v-if="msg.privacy.length">{{
                        msg.privacy
                      }}</span>
                    </div>
                    <div>
                      <input
                        name="requirements"
                        id="requirements"
                        type="checkbox"
                        class="custom-control-input"
                        value="requirements"
                        v-model="form.requirements"
                        :disabled="loadingFormRequest"
                        :class="{ 'su-error-field': msg.requirements.length }"
                        @change="msg.requirements = ''"
                      />
                      <label for="requirements" class="custom-control-label">
                        I declare to possess the required qualifications for this application*
                      </label>
                      <span class="su-error-field-text" v-if="msg.requirements.length">{{
                        msg.requirements
                      }}</span>
                    </div>
                    <div class="required_fields_label">
                      <label>*Required fields</label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <footer-row></footer-row>

      <alert-row ref="alertMsg"></alert-row>
    </div>
  </section>
</template>

<script lang="js">
import Vue from "vue";
import SecondaryNavBar from "@/components/secondary-nav";
import Footer from "@/components/footer.vue";
import Alert from "@/components/alert";
import axios from "axios";

export default Vue.extend({
  name: "contact-tech",
  metaInfo: {
    title: "Contact - Technician",
  },
  components: {
    "secondary-nav": SecondaryNavBar,
    "footer-row": Footer,
    "alert-row": Alert,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        privacy: "",
        page: "Technician",
        fileName: "",
        requirements: "",
      },
      msg: {
        name: "",
        email: "",
        privacy: "",
        message: "",
        cv: "",
        requirements: "",
      },
      responseData: "",
      loadingFormRequest: false,
    };
  },
  methods: {
    getFileName() {
      if (this.$refs.file.files.length > 0)
        this.$data.form.fileName = this.$refs.file.files[0].name;
      else this.$data.form.fileName = "";
    },
    validateEmail(value) {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(value);
    },
    validateName(value) {
      return /^[A-Za-z ]+$/.test(value);
    },
    toFormData(obj, formData) {
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    submitEntry() {
      var localError = false;
      this.$data.loadingFormRequest = true;

      let formData = new FormData();
      this.toFormData(this.$data.form, formData);

      if (this.$refs.file.files.length > 0) {
        formData.append("attachment", this.$refs.file.files[0]);
      } else {
        this.$data.msg.cv = "A CV is Required.";
        localError = true;
      }

      if (!this.$data.form.name.length) {
        this.$data.msg.name = "Name field cannot be void.";
        localError = true;
      } else if (!this.validateName(this.$data.form.name)) {
        this.$data.msg.name = "Name field not valid. Only letters are admitted.";
        localError = true;
      }

      if (!this.$data.form.email.length) {
        this.$data.msg.email = "Email field cannot be void.";
        localError = true;
      } else if (!this.validateEmail(this.$data.form.email)) {
        this.$data.msg.email = "Email format not valid.";
        localError = true;
      }

      if (!this.$data.form.privacy) {
        this.$data.msg.privacy = "You must accept the privacy policy.";
        localError = true;
      }

      if (!this.$data.form.requirements) {
        this.$data.msg.requirements = "You must respect all required qualifications.";
        localError = true;
      }

      if (localError) {
        this.$data.loadingFormRequest = false;
        return;
      }

      this.$refs.alertMsg.showAlert(
        "Sending message. Please wait, it could take a while based on your connection."
      );

      axios
        .post("/php/showup_form.php", formData)
        .then((response) => {
          var infoResponse = response.data;
          this.$refs.alertMsg.showAlert(infoResponse.message);

          if (infoResponse.status) {
            this.$data.form.email = "";
            this.$data.form.name = "";
            this.$data.form.privacy = false;
          }

          this.$data.loadingFormRequest = false;
        })
        .catch((error) => {
          this.$refs.alertMsg.showAlert(error);
          this.$data.loadingFormRequest = false;
        });
    },
  },
});
</script>
