<template>
  <section class="about">

    <secondary-nav></secondary-nav>

    <div class="su-body">

      <div class="about-us-section container">
        <div class="row">
          <div class="col-1 void-column"></div>

          <div class="col-11 col-md-10">
            <div class="row">
              <div class="col-12 policy-content" v-html="privacy_policy">
              </div>
            </div>

            <footer-row></footer-row>

            <alert-row ref="alertMsg"></alert-row>

          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script lang="js">
import Vue from 'vue';
import SecondaryNavBar from "@/components/secondary-nav";
import axios from "axios";
import Alert from '@/components/alert.vue'

export default Vue.extend({
  name: 'privacy-policy',
  metaInfo: {
    title: 'Privacy Policy',
  },
  data(){
    return {
      privacy_policy : "",
    }
  },
  methods:{
    getPrivacyPolicyBody: function() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      var self = this;

      axios.get('https://www.iubenda.com/api/privacy-policy/20588229')
        .then(response => {
          self.$data.privacy_policy = response.data.content;
        })
        .catch(error => {
          this.$refs.alertMsg.showAlert(error);
        });
    },
  },
  components: {
    'secondary-nav' : SecondaryNavBar,
    'alert-row' : Alert
  },
  created() {
    this.getPrivacyPolicyBody();
  }
});
</script>
